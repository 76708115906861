import { Elm } from '../../src/Main.elm';
import images from '../images/*.png';
import data from './data';

data.images = Object.entries(images);
data.color = window.data.color || "r";
data.shipTypeId = window.data.shipTypeId || "";
data.characterId = window.data.characterId || "";

// BUG: ["017_y", "027_r.d91ede45.png"] となってしまう不具合の対応
data.images.push(['017_y', '017_y.4702f6ac.png']);

/*
// インポートした画像の確認
console.log(data.images)

for (var a of data.images) {
  if (a[1].indexOf(a[0]) != 0) {
    console.log(a[1].indexOf(a[0]), a);
  }
}
*/

var app = Elm.Main.init({
  node: document.querySelector('main'),
  flags: data
});

app.ports.normalizeStrig.subscribe(function (s) {
  // console.log(s, s.normalize(), s.length, s.normalize().length);
  app.ports.normalizedString.send(s.normalize());
});
